import { Tabs } from "flowbite-react";

export default function Tab({ user, property }) {
  return (
    <div className="ms-16 mb-16 ">
      <Tabs>
        <Tabs.Item
          active
          title={<span className="text-xl text-green-600">Description</span>}
          className=""
        >
          <div className="mb-5">
            <p className="text-md">{property.description}</p>
          </div>
        </Tabs.Item>
        <Tabs.Item
          title={
            <span className="text-xl text-green-600">Contact Details</span>
          }
          className="text-slate-500 text-lg "
        >
          <div className="">
            {user.length > 0 ? (
              <>
                <div className="mb-5">
                  <div className="flex">
                    <p className="text-slate-500">Name: </p>
                    <p className="ms-3">{user[0].name.toUpperCase()}</p>
                  </div>
                  <div className="flex">
                    <p className="text-slate-500">Phone: </p>
                    <p className="ms-3">{user[0].phone}</p>
                  </div>
                  {/* <div className="flex">
                    <p className="text-slate-500">Email: </p>
                    <p className="ms-3">{user[0].email}</p>
                  </div> */}
                </div>
              </>
            ) : null}
          </div>
        </Tabs.Item>
        <Tabs.Item
          title={<span className="text-xl text-green-600">Images</span>}
          className="text-slate-500 text-lg "
        >
          <div className="flex">
            {property?.property_images?.map((image) => (
              <div className="w-1/5 relative group mt-2" key={image._id}>
                <a
                  href={`${image.file_path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ps-3"
                >
                  <img
                    className="w-24 h-[80px]"
                    src={`${image.file_path}`}
                    alt={image.file_name}
                  />
                </a>
              </div>
            ))}
          </div>
        </Tabs.Item>
        <Tabs.Item
          title={<span className="text-xl text-green-600">Documents</span>}
          className="text-slate-500 text-lg "
        >
          <div className=" flex flex-col w-1/2">
            {/* Lagan Receipt */}
            {property.lagan_receipt && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.lagan_receipt.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Lagan Receipt
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <p>{property.lagan_receipt.file_name}</p>
                </div>
              </div>
            )}

            {/* Sale Deed */}
            {property.sale_deed && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.sale_deed.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Sale Deed
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.sale_deed.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    {property.sale_deed.file_name}
                  </a>
                  <p></p>
                </div>
              </div>
            )}

            {/* Land Revenue Record */}
            {property.land_revenue_record && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.land_revenue_record.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Land Revenue Record
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.land_revenue_record.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    {property.land_revenue_record.file_name}
                  </a>
                </div>
              </div>
            )}

            {/* Land Map */}
            {property.land_map && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.land_map.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Land Map
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.land_map.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    {property.land_map.file_name}
                  </a>
                </div>
              </div>
            )}

            {/* Partition Deed */}
            {property.partition_deed && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.partition_deed.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Partition Deed
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.partition_deed.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    {property.partition_deed.file_name}
                  </a>
                </div>
              </div>
            )}

            {/* Mutation */}
            {property.mutation && (
              <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.mutation.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    Mutation
                  </a>
                </div>
                <div className="w-1/2 text-center">
                  <a
                    href={`${property.mutation.file_path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ps-3"
                  >
                    {property.mutation.file_name}
                  </a>
                </div>
              </div>
            )}
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
