import React, { useEffect, useState } from "react";
import { Accordion } from "flowbite-react";
import axios from "axios";
import { toast } from "react-toastify";

function Faq() {
  const [fee, setFee] = useState(49);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/admin/display-fee`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setFee(response.data.data.property_view_fee);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Invalid Credentials !!");
        } else {
          toast.error("Server Error !!");
        }
      });
  }, []);

  return (
    <div className="bg-green-950 py-10 text-white">
      <div className="m-5 lg:mx-24 mt-0">
        <h1 className="text-xl lg:text-4xl font-bold mb-5 text-white">FAQ</h1>
        <div className="grid lg:grid-cols-2 gap-4">
          {/* First row */}
          <div>
            <Accordion collapseAll className="faq-section border-0 lg:border">
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  How do I register as a buyer, consultant or seller on Plotts?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Sign up with your basic details to create an account. Once
                    registered, you can browse listings or list your plot for
                    sale.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  How much do I need to pay to access contact details and plot
                  documents?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    For a nominal fee of INR {fee}, you can unlock seller
                    contact details and key plot-related documents, ensuring
                    complete transparency before making any commitments.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  Can I inspect the plot before purchasing?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Absolutely! After accessing the seller's contact
                    information, you can schedule a site visit to inspect the
                    plot and discuss details in person.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  What is the process of listing a plot for sale on Plotts?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Once you sign up, you can easily list your plot by providing
                    details such as plot size, price, location, and uploading
                    key documents. After approval, your listing will be visible
                    to potential buyers.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  What documents can I expect to receive when purchasing a plot?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    You will receive key documents such as the Lagan Receipt,
                    Sale Deed, Land Map, Mutation and other legal documents
                    confirming ownership and the plot’s legal status.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  How do I know the buyer is genuine?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    At Plotts, we encourage buyers to register and complete
                    their profiles. However, we recommend you take basic
                    precautions like verifying the buyer's information and
                    arranging safe meeting points to discuss the sale.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
          <div>
            <Accordion collapseAll className="faq-section border-0 lg:border">
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  How long will my plot be listed on Plotts?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Your plot will remain listed on our platform until it is
                    sold, or until you choose to remove the listing. We also
                    offer promotional features for better visibility, should you
                    wish to attract more buyers.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  Can I negotiate the price with the seller?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Yes, once you have accessed the seller’s contact details,
                    you can directly negotiate with the seller to arrive at a
                    mutually agreeable price.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  Can I post multiple plots for sale?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Yes, you can list multiple plots for sale on Plotts. Each
                    plot can be listed with its own set of details and
                    documents.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  What happens if I am unable to connect with a seller after
                  paying the fee?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    If you're unable to reach the seller after paying for
                    contact details, please contact our support team for
                    assistance. We’ll ensure the issue is resolved or offer a
                    refund if the seller is unresponsive.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  Is the service available in all states of India?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Currently, we are operating in Bihar, Jharkhand, Uttar
                    Pradesh, Madhya Pradesh, and Chhattisgarh. We will soon be
                    expanding our services to more states across India. Stay
                    tuned for updates!
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="accordion-head">
                  Can I add rural agricultural plots for sale on Plotts?
                </Accordion.Title>
                <Accordion.Content className="accordion-content">
                  <p className="mb-2 text-white">
                    Yes, you can add rural agricultural plots for sale on
                    Plotts. Our platform supports listings for all types of
                    land, including rural agricultural plots.
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
