import React, { useEffect, useState } from "react";
import land1 from "../Components/assets/land1.jpg";
import background from "../Components/assets/background.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLoaderContext } from "../Context/LoaderContext";
import axios from "axios";
function Hero() {
  const { loader, setLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [mandals, setMandals] = useState([]);
  const [selectedState, setSelectState] = useState();
  const [selectedDistrict, setSelectDistrict] = useState();
  const [searchData, setSearchData] = useState([]);
  const token = localStorage.getItem("token");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setStates(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district`,
          {
            state: selectedState,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-mandals`,
          {
            district: selectedDistrict,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setMandals(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDistrict]);

  const submit = (details) => {
    const { state, district, mandal } = details;
    if (state && district && mandal) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/property/search-properties`,
          {
            state,
            district,
            mandal,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setSearchData(response.data.data);
            navigate("/search", {
              state: {
                searchData: response.data.data,
                state,
                district,
                mandal,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="bg-hero-bg bg-bottom bg-cover pb-24 flex justify-center items-center py-5 h-full">
        <div className="grid md:grid-cols-2 items-center md:grid-flow-col mx-5">
          <div className="">
            <div className="hero-content w-[100%]">
              <h1 className="text-2xl lg:text-5xl xl:text-6xl font-medium text-green-950">
                Easily Buy and Sell your{" "}
                <span className="text-yellow-500">Plots with Plotts</span>
              </h1>
              <p className="mt-5 text-md lg:text-lg text-slate-500">
                Your trusted platform for both buyers and sellers of
                residential, commercial, or agricultural plots. Plotts
                simplifies land transactions, connecting you directly with
                potential buyers or sellers. Start your journey today and
                explore or list verified plots with ease!
              </p>
            </div>
            <div className="hero-search p-4 bg-green-900 mt-10 rounded-lg w-fit">
              <form
                className="lg:flex gap-2 lg:gap-5 space-y-2 lg:space-y-0"
                onSubmit={handleSubmit(submit)}
              >
                {/* State */}
                <select
                  className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
                  {...register("state", {
                    required: "This field is required",
                  })}
                  onChange={(e) => setSelectState(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states.length > 0 &&
                    states.map((option) => (
                      <option key={option._id} value={option.state}>
                        {option.state.toUpperCase()}
                      </option>
                    ))}
                </select>
                {/* District */}
                <select
                  className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
                  {...register("district", {
                    required: "This field is required",
                  })}
                  onChange={(e) => setSelectDistrict(e.target.value)}
                >
                  <option value="">Select District</option>
                  {districts.length > 0 &&
                    districts.map((option) => (
                      <option key={option._id} value={option.district}>
                        {option.district.toUpperCase()}
                      </option>
                    ))}
                </select>
                {/* Mandal */}
                <select
                  className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
                  {...register("mandal", {
                    required: "This field is required",
                  })}
                >
                  <option value="">Select Tehsil</option>
                  {mandals.length > 0 &&
                    mandals.map((option) => (
                      <option key={option._id} value={option.mandal}>
                        {option.mandal.toUpperCase()}
                      </option>
                    ))}
                </select>
                {/* Submit button */}
                <button
                  type="submit"
                  className="md:block bg-yellow-400 hover:bg-yellow-600 text-lg hover:text-white text-black font-medium py-1 px-5 rounded-xl w-[100%]"
                >
                  Search
                </button>

                {/* Clear button */}
                {/* <button
                    type="button"
                    className="md:block bg-red-500 hover:bg-red-700 text-lg text-white font-medium py-1 px-5 rounded-xl my-2"
                    onClick={handleClear}
                  >
                    Clear
                  </button> */}
              </form>
            </div>
          </div>
          <div className="w-[90%] ml-auto">
            <div className="pt-2">
              <img
                className="rounded-lg mx-auto hero-img hidden md:block"
                src={land1}
                alt="Image Not Found"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
