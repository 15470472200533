import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoaderContext } from "../Context/LoaderContext";

function AddProperty() {
  const { loader, setLoader } = useLoaderContext();
  const selectRef = useRef(null);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [mandals, setMandals] = useState([]);
  const [selectedState, setSelectState] = useState();
  const [selectedDistrict, setSelectDistrict] = useState();
  const [fileUpload, setFilUpload] = useState([]);
  const [isDecTypeSelect, setIsDocTypeSelect] = useState("");
  const [sizeUnits, setSizeUnits] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchUnits = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/display-units`, {
          withCredentials: true, // Include credentials if needed
          headers: {
            "x-token": token,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setSizeUnits(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

    return () => {
      clearTimeout(fetchUnits);
    };
  }, [token]);

  useEffect(() => {
    const fetchState = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
          withCredentials: true, // Include credentials if needed
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setStates(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
    return () => {
      clearTimeout(fetchState);
    };
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district`,
          {
            state: selectedState,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-mandals`,
          {
            district: selectedDistrict,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setMandals(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDistrict]);

  const submit = (details) => {
    setLoader(true);
    const formData = new FormData();
    Object.keys(details).forEach((key) => {
      if (key !== "propertyImage" || key !== "documents") {
        formData.append(key, details[key]);
      }
    });

    if (details.property_images && details.property_images.length > 0) {
      for (let i = 0; i < details.property_images.length; i++) {
        formData.append("propertyImage", details.property_images[i]);
      }
    }

    // Append documents
    if (fileUpload && fileUpload.length > 0) {
      for (let i = 0; i < fileUpload.length; i++) {
        formData.append(fileUpload[i].file_type, fileUpload[i].file);
      }
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/property/add-property`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setLoader(false);
          toast.success("Propert Added");
          navigate("/myproperties");
        }
      })
      .catch((err) => {
        toast.error("Propert Not Added");
        console.log(err);
      });
  };
  const removeFileDate = (index) => {
    setFilUpload((prev) => prev.filter((_, id) => id !== index));
  };
  const handleFileUpload = (file) => {
    setIsDocTypeSelect("");
    let currValue = selectRef.current.value;
    setFilUpload((prev) => [
      ...prev,
      {
        file,
        file_type: currValue,
      },
    ]);
    selectRef.current.value = "";
  };
  const handleSelectDocType = () => {
    setIsDocTypeSelect(selectRef.current.value);
  };
  return (
    <div className="w-full mt-10">
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex justify-center">
          <div className="bg-white ps-4 pe-4 gap-2 w-full lg:w-5/6 2xl:w-4/6">
            <div className="flex justify-center">
              <h1 className="mt-4 text-green-950 font-bold text-3xl">
                Add Land Details
              </h1>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 mt-8 gap-5">
              {/*  State , District ,Mandal */}
              <div className="flex flex-col">
                <p className="pb-1">Select State*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("state", {
                      required: "This field is required",
                    })}
                    onChange={(e) => setSelectState(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {states.length > 0 &&
                      states.map((option) => (
                        <option key={option._id} value={option.state}>
                          {option.state}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.state?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1">District*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("district", {
                      required: "This field is required",
                    })}
                    onChange={(e) => setSelectDistrict(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {districts.length > 0 &&
                      districts.map((option) => (
                        <option key={option._id} value={option.district}>
                          {option.district}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.district?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1">Mandal*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("mandal", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select an option</option>
                    {mandals.length > 0 &&
                      mandals.map((option) => (
                        <option key={option._id} value={option.mandal}>
                          {option.mandal}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.city?.message}</p>
              </div>
              {/* Price*/}
              <div className="flex flex-col">
                <p className="pb-1">Select Units*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    name="units"
                    id="units"
                    {...register("units", { required: "Select a unit" })}
                    className="bg-transparent focus-visible:outline-none w-full"
                    required
                  >
                    <option value="">Select Units</option>
                    {sizeUnits.length > 0 &&
                      sizeUnits.map((unit) => (
                        <option value={unit.unit} key={unit._id}>
                          {unit.unit}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.size?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1">Size*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <input
                    required
                    placeholder="Size"
                    type="number"
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("size", {
                      required: "Size is required",
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.size?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1">Price*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <input
                    required
                    placeholder="Price"
                    type="number"
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("price", {
                      required: "Price is required",
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.price?.message}</p>
              </div>

              {/* Description */}
              <div className="col-span-2 md:col-span-3 flex flex-col">
                <p className="pb-1">Description*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <textarea
                    required
                    placeholder="Description"
                    className="bg-slate-300 focus:outline-none w-full h-24 resize-none"
                    {...register("description", {
                      required: "Description is required",
                      pattern: {
                        value: /^(?:(?!\d{9})[\s\S])*$/,
                        message: "No more than 8 consecutive digits allowed",
                      },
                      validate: (field) => {
                        return (
                          field.trim().length >= 20 ||
                          "Description must be at least 20 characters"
                        );
                      },
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.description?.message}</p>
              </div>
              {/* Documents */}
              {/* Images */}
              <div className="col-span-2 md:col-span-1 flex flex-col">
                <p className="pb-1">Property Images*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <input
                    required
                    type="file"
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("property_images", {
                      required: "Property images are required",
                    })}
                    multiple
                  />
                </div>
                <p className="text-red-500">
                  {errors.property_images?.message}
                </p>
              </div>
              <div className="col-span-2 flex flex-col gap-2">
                <p>Upload All Available Documents </p>
                <select
                  className="bg-slate-300 focus:outline-none py-2 rounded-lg"
                  ref={selectRef}
                  onChange={() => handleSelectDocType()}
                >
                  <option value="">Select an option</option>
                  <option value="lagan_receipt">लगान रसीद/Lagan Receipt</option>
                  <option value="sale_deed">सेल डीड/Sale Deed</option>
                  <option value="land_map">भूमि का नक्शा/Land Map</option>
                  <option value="land_revenue_record">
                    ज़मीन की जमाबंदी/Land Revenue Record
                  </option>
                  <option value="partition_deed">
                    पारिवारिक बंटवारा पत्र/Partition Deed
                  </option>
                  <option value="mutation">दाखिल ख़ारिज/Mutation</option>
                  <option value="record_of_rights">
                    खतियान/Record of Rights - RoR
                  </option>
                </select>
                {isDecTypeSelect !== "" ? (
                  <>
                    <label
                      htmlFor="inputField"
                      className={`w-fit bg-green-950 hover:bg-green-800 text-white font-medium py-2 px-5 rounded-lg text-center cursor-pointer`}
                    >
                      Upload
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id="inputField"
                      onChange={(e) =>
                        e.target.value
                          ? handleFileUpload(e.target.files[0])
                          : null
                      }
                    />
                  </>
                ) : (
                  <>
                    <label className="w-fit bg-green-950/50 py-2 px-5 rounded-lg text-white font-medium text-center">
                      Upload
                    </label>
                  </>
                )}
                {fileUpload.length > 0
                  ? fileUpload.map((item, index) => (
                      <div
                        key={index}
                        className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100"
                      >
                        <div className="w-[273px]">
                          <p className="">{item.file_type}</p>
                        </div>
                        <div className="w-[273px] ms-4">
                          <p>{item.file.name}</p>
                        </div>
                        <div className="ms-auto me-3">
                          <i
                            className="fa-solid fa-trash text-red-600 cursor-pointer"
                            onClick={() => removeFileDate(index)}
                          ></i>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            {/* Submit Button */}
            <div className="flex gap-5 items-center p-2 rounded-md mt-6 justify-center">
              {!loader ? (
                <>
                  <button className="rounded-2xl py-2 px-8 text-white font-bold bg-yellow-500 hover:bg-yellow-600">
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <div className="w-8 h-8 border-4 border-green-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProperty;
