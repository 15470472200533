import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SubscriptionCard from "../Components/SubscriptionCard";

const Subscription = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const display = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/plan/display-plans`, {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setPlans(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch users");
        });
    });
    return () => {
      clearTimeout(display);
    };
  }, [token]);

  const handlePayment = (order) => {
    const options = {
      key: order.key, // Razorpay API key
      amount: order.order.amount, // Amount in paise
      currency: "INR",
      name: "Plotts",
      description: "Transaction",
      order_id: order.order.id, // Razorpay order_id
      handler: async function (response) {
        // Handle the payment response and send it to your backend for verification
        const payment = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/payment/add-payment-details`,
          {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            amount: order.amount,
            plan_id: order.plan_id,
          },
          {
            headers: {
              "x-token": token, // Send token in custom header
            },
            withCredentials: true, // Include credentials if needed
          }
        );
        if (payment.data.statusCode === 200) {
          toast.success("Payment successful, you can view properties now");
          navigate("/properties");
        }
      },
      prefill: {
        name: "Customer Name",
        email: "customer@example.com",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const handleCheckout = async (data) => {
    // try {
    //   const response = await axios.post(
    //     `${process.env.REACT_APP_BACKEND_URL}/payment/checkout`,
    //     {
    //       plan_id: id,
    //     },
    //     {
    //       headers: {
    //         "x-token": token, // Send token in custom header
    //       },
    //       withCredentials: true, // Include credentials if needed
    //     }
    //   );
    //   if (response.status === 200) {
    //     handlePayment(response.data.data);
    //   }
    // } catch (err) {
    //   console.log(err);
    //   return null;
    // }

    localStorage.setItem("plan_name", data.plan_type);
    localStorage.setItem("plan_amount", data.final_amount);
    localStorage.setItem("plan_id", data._id);
    navigate("/checkout");
  };

  return (
    <div className="p-5">
      <h3 className="text-2xl font-semibold mb-7 text-center">
        Subscription Plans
      </h3>
      <div className="grid grid-cols-6 gap-y-3">
        {plans.length > 0 ? (
          plans.map((plan, index) => (
            <SubscriptionCard
              key={plan._id}
              data={plan}
              index={index}
              handleCheckout={handleCheckout}
            />
          ))
        ) : (
          <p>No active plans found.</p>
        )}
      </div>
    </div>
  );
};

export default Subscription;
