import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import axios from "axios";

export const ForgotPasswordModal = ({ open, onClose }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/forget-password`,
        { email },
        {
          withCredentials: true,
        }
      );

      if (response.data.status === 200) {
        onClose();
      } else {
        setErrorMessage("Invalid email or request failed.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
      console.error("Error submitting form:", error);
    }
  };

  if (!open) return null;

  return (
    <Modal
      show={open}
      onClose={onClose}
      className="w-6/12 !top-1/4 !left-1/2 -translate-x-1/2"
    >
      <div className="flex justify-center items-center">
        <div className="w-full h-full bg-slate-200 rounded-lg shadow-lg">
          <Modal.Header>Forget Password</Modal.Header>
          <Modal.Body>
            <form>
              <div className="flex items-center mb-4">
                <i className="fa-solid fa-envelope ps-2"></i>
                <input
                  required
                  placeholder="Email"
                  type="email"
                  className="bg-slate-300 focus:outline-none p-2 ml-2 rounded-md"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit} className="bg-green-500">
              I accept
            </Button>
            <Button className="bg-red-500 ms-5" onClick={onClose}>
              Decline
            </Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};
