import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
function Search() {
  const location = useLocation();
  const data = location.state || { searchData: [] };
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [mandals, setMandals] = useState([]);
  const [selectedState, setSelectState] = useState();
  const [selectedDistrict, setSelectDistrict] = useState();
  const [searchData, setSearchData] = useState(data.searchData);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setStates(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district`,
          {
            state: selectedState,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-mandals`,
          {
            district: selectedDistrict,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setMandals(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDistrict]);

  const handleView = (id) => {
    navigate(`/property/${id}`);
  };

  const handleClear = () => {
    reset({
      state: "",
      district: "",
      mandal: "",
    });
  };

  const submit = (details) => {
    const { state, district, mandal } = details;
    if (state && district && mandal) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/property/search-properties`,
          {
            state,
            district,
            mandal,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setSearchData(response.data.data);
            handleClear();
            // navigate("/search", { state: { searchData: response.data.data } });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className="text-center text-3xl font-bold mt-5">
        Searched Properties
      </div>
      <div className="hero-search p-4 bg-green-900 mt-10 rounded-lg w-11/12 sm:w-6/12 mx-auto">
        <form
          className="flex flex-col lg:flex-row gap-2 lg:gap-5 justify-center"
          onSubmit={handleSubmit(submit)}
        >
          {/* State */}
          <select
            className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
            {...register("state", {
              required: "This field is required",
            })}
            onChange={(e) => setSelectState(e.target.value)}
          >
            <option value="">Select State</option>
            {states.length > 0 &&
              states.map((option) => (
                <option key={option._id} value={option.state}>
                  {option.state.toUpperCase()}
                </option>
              ))}
          </select>
          {/* District */}
          <select
            className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
            {...register("district", {
              required: "This field is required",
            })}
            onChange={(e) => setSelectDistrict(e.target.value)}
          >
            <option value="">Select District</option>
            {districts.length > 0 &&
              districts.map((option) => (
                <option key={option._id} value={option.district}>
                  {option.district.toUpperCase()}
                </option>
              ))}
          </select>
          {/* Mandal */}
          <select
            className="bg-slate-50 focus:outline-none rounded-lg p-2 sm:mb-4 lg:mb-0 w-[100%] lg:w-auto"
            {...register("mandal", {
              required: "This field is required",
            })}
          >
            <option value="">Select Tehsil</option>
            {mandals.length > 0 &&
              mandals.map((option) => (
                <option key={option._id} value={option.mandal}>
                  {option.mandal.toUpperCase()}
                </option>
              ))}
          </select>
          {/* Submit button */}
          <button
            type="submit"
            className="md:block bg-yellow-400 hover:bg-yellow-600 text-lg hover:text-white text-black font-medium py-1 px-5 rounded-xl"
          >
            Search
          </button>

          {/* Clear button */}
          {/* <button
                    type="button"
                    className="md:block bg-red-500 hover:bg-red-700 text-lg text-white font-medium py-1 px-5 rounded-xl my-2"
                    onClick={handleClear}
                  >
                    Clear
                  </button> */}
        </form>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3  md:gap-6 max-sm:gap-2 justify-center items-center md:pe-24 md:ps-24 md:pt-12 md:pb-12 max-sm:mt-12">
        {searchData.length > 0 ? (
          searchData.map((item, index) => (
            <div
              key={item._id}
              className="shadow-lg shadow-black-500 rounded-lg mb-6 relative bg-white"
            >
              {/* image */}
              <div className="">
                <img
                  className="w-full rounded-md"
                  src={`${item.property_images[0].file_path}`}
                  alt={item.property_images[0].file_name}
                />
              </div>
              <div className="p-4">
                <p className="font-medium text-xl line-clamp-1">{item.title}</p>
                {/* Location */}
                <div className="flex justify-between text-slate-500 text-sm">
                  <h1>
                    <span>
                      <i className="fa-solid fa-location-dot pe-1"></i>
                    </span>
                    {item.mandal}, {item.district}, {item.state}
                  </h1>
                  <h1 className="font-medium">
                    {item.size} {item.units}
                  </h1>
                </div>
                {/* Button */}

                <div className="flex items-center gap-6 mt-3 pt-3 border-t-2 border-slate-200 w-full">
                  <span className="w-[50%] text-yellow-500 font-bold p-2 rounded-lg">
                    {" "}
                    <i className="fa-solid fa-indian-rupee-sign pe-1"></i>
                    {Math.ceil(item.price / item.size)} / {item.units}
                  </span>
                  <button
                    className="bg-green-950 rounded-lg px-6 py-1 text-white font-bold items-start hover:bg-green-700 w-[50%] h-fit"
                    onClick={() => {
                      handleView(item._id);
                    }}
                  >
                    View
                  </button>
                </div>
                <p className="text-sm text-end">
                  Total Views: {item.view_count}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center col-span-4 md:ps-15">
            <h1 className="text-2xl font-bold">Property not found</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
