import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { PaymentConfirm } from "../Model/PaymentConfirm";
import Tab from "../Components/Tab";
import { useLoaderContext } from "../Context/LoaderContext";
import LoaderModal from "../Model/LoaderModal";

function PropertyDetails() {
  const { loader, setLoader } = useLoaderContext();
  const [property, setProperty] = useState([]);
  const [propertyUser, setPropertyUser] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({
    isViewed: 0,
    viewCountTokens: 0,
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  let userId = localStorage.getItem("id");
  const { id } = useParams();

  const handleUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/user-details/${id}`,
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      );
      setPropertyUser(response.data.data);
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [token, id]);

  const userData = useCallback(async () => {
    const userResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/payment/payment-status/${id}`,
      {
        headers: { "x-token": token },
        withCredentials: true,
      }
    );
    const res = userResponse.data.data;
    setUser({
      isViewed: res.view_status,
      viewCountTokens: res.plan_status,
    });
  }, [token, id]);

  useEffect(() => {
    setLoader(true);
    const fetchPropertyDetails = setTimeout(async () => {
      try {
        const propertyResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/property/display-one-property/${id}`,
          { withCredentials: true }
        );
        const propertyRes = await propertyResponse.data;
        if (propertyRes.statusCode === 200) {
          setProperty(propertyRes.data);
          await handleUserDetails();
          setLoader(false);
        } else {
          toast.error("Property not found");
        }
        if (propertyRes.data.user_id === userId) {
          setUser((prev) => ({ ...prev, isViewed: 1 }));
        } else if (token) {
          userData();
        }
      } catch (err) {
        toast.error("Failed to fetch property details");
      }
    });

    return () => {
      clearTimeout(fetchPropertyDetails);
    };
  }, [id, token, userId, handleUserDetails, userData]);

  const viewProperty = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/add-history`,
        { property_id: id },
        {
          headers: {
            "x-token": token, // Send token in custom header
          },
          withCredentials: true, // Include credentials if needed
        }
      );
      if (response.data.statusCode === 200) {
        setUser((prev) => ({ ...prev, isViewed: 1 }));
        toast.success("Success, you can view property details now");
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [token, id]);

  const checkViewTokens = () => {
    if (user.viewCountTokens > 0) {
      setOpenModal(true);
    } else {
      toast.warning("Need to buy subscription plan");
      navigate("/subscription");
    }
  };
  if (loader) {
    return (
      <div className="col-span-full mx-auto">
        <LoaderModal />
      </div>
    );
  }
  console.log("usssusus", user);
  return (
    <div>
      <PaymentConfirm
        open={openModal}
        onClose={() => setOpenModal(false)}
        viewProperty={viewProperty}
      />

      {property.length === 0 && !loader ? (
        <div>
          <h1>Property Not Found</h1>
        </div>
      ) : (
        <div className="grid md:grid-cols-3 max-sm:grid-row-2 md:m-8 md:gap-8 md:items-center">
          <div className="md:ps-8 max-sm:ps-3 max-sm:order-2 max-sm:mb-10 col-start-1 col-end-3">
            {/* Title */}
            <div className="mb-5">
              <h1 className="text-3xl font-bold">{property[0].title}</h1>
            </div>
            {/* Location */}
            <div className="flex items-center gap-2 md:mb-5 max-sm:mb-3">
              <i className="fa-solid fa-location-dot text-red-600"></i>
              <p>{property[0].mandal},</p>
              <p>{property[0].district},</p>
              <p>{property[0].state}</p>
            </div>
            {/* Pice */}
            <div className="flex gap-12 md:mb-5 max-sm:mb-3 items-center">
              <p>
                <span className="text-slate-500">Total Price :</span>
                <i className="fa-solid fa-indian-rupee-sign me-1 ms-1"></i>
                {property[0].price}
              </p>
              <p>
                <span className="text-slate-500">Size :</span>{" "}
                {property[0].size} {property[0].units}
              </p>
              <p>
                <span className="text-slate-500">Total Views :</span>{" "}
                {property[0].view_count}
              </p>
            </div>

            {/* Description */}
            <div className="mb-5">
              <p className="text-slate-500 text-lg underline decoration-solid underline-offset-4">
                Description :
              </p>
              <p
                className={`text-md  ${
                  property[0]?.description?.length > 0
                    ? "text-slate-500"
                    : "text-red-500"
                }`}
              >
                {property[0]?.description?.length > 0
                  ? property[0]?.description
                  : "NA"}
              </p>
            </div>

            {/* Button */}

            {!user.isViewed && token && (
              <div className="flex max-sm:justify-center mt-5">
                <button
                  className="rounded-2xl px-8 py-2 bg-yellow-500 font-bold hover:bg-yellow-600"
                  onClick={() => {
                    checkViewTokens();
                  }}
                >
                  View Details
                </button>
              </div>
            )}
            {!token && (
              <Link
                to="/login"
                className="rounded-2xl px-8 py-2 bg-yellow-500 font-bold hover:bg-yellow-600"
              >
                View Details
              </Link>
            )}
          </div>
          <div className="max-sm:order-1">
            <img
              className="rounded-lg"
              src={`${property[0]?.property_images[0]?.file_path}`}
              alt={property[0]?.property_images[0]?.file_name}
            />
          </div>
        </div>
      )}

      {user.isViewed ? (
        <>
          <Tab user={propertyUser} property={property[0]} />
        </>
      ) : null}
    </div>
  );
}

export default PropertyDetails;
