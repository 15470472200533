import React, { useEffect, useState } from "react";
import logo from "../Components/assets/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [status, setStatus] = useState("Login");
  let logStatus = localStorage.getItem("status") || "Login";
  let role = localStorage.getItem("role") || "";
  useEffect(() => {
    setStatus(logStatus);
  }, [logStatus]);

  const handleLogOut = () => {
    localStorage.removeItem("status");
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <div className="flex justify-between items-center p-3 shadow-md z-10 shadow-black-500 w-full h-[80px] bg-green-950 text-white">
      <Link to="/">
        <img className="rounded-full w-[125px] " src={logo} alt="Logo" />
      </Link>
      <div className="hidden md:flex gap-8">
        <Link
          to="/"
          className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
        >
          Home
        </Link>
        <Link
          to="/about"
          className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
        >
          About
        </Link>
        <Link
          to="/contact"
          className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
        >
          Contact
        </Link>
        <Link
          to="/properties"
          className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
        >
          Properties
        </Link>
        {status === "Log Out" && role != "admin" && (
          <>
            <Link
              to="/myproperties"
              className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
            >
              My Properties
            </Link>
          </>
        )}

        {status === "Log Out" && role == "admin" && (
          <>
            <Link
              to="/admin/users"
              className="text-lg font-medium hover:bg-yellow-400 hover:text-black rounded-md px-2"
            >
              Back To Admin
            </Link>
          </>
        )}
      </div>
      
      <div className="flex items-center gap-x-3">
        <Link
          to={!token ? "/login" : "/addProperty"}
          className="hidden md:block bg-white text-green-950 font-bold py-1 px-6 rounded-lg"
        >
          List Property
        </Link>
        <Link to="/login">
          <button
            className="hidden md:block bg-yellow-400 text-black hover:bg-yellow-600 font-bold py-1 px-5 rounded-lg"
            onClick={status === "Log Out" ? handleLogOut : null}
          >
            {status}
          </button>
        </Link>
        <div id="translate_element"></div>
      </div>
      <button className="p-3 md:hidden" onClick={() => setIsMenuOpen(true)}>
        <i className="fa-solid fa-xl fa-bars text-white"></i>
      </button>
      {isMenuOpen && (
        <div className="fixed top-0 left-0 w-full h-screen bg-white shadow-md flex flex-col gap-4 md:hidden">
          <div className="flex items-center justify-between bg-green-950 p-3">
            <Link to="/">
              <img className="rounded-full w-[110px]" src={logo} alt="Logo" />
            </Link>
            <i
              className="fa-solid fa-xl fa-bars ml-auto rotate-90 my-3"
              onClick={() => setIsMenuOpen(false)}
            ></i>
          </div>
          <div className="flex flex-col p-5 gap-y-3">
            <Link
              to="/"
              className="text-lg font-medium text-black"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="text-lg font-medium text-black"
              onClick={() => setIsMenuOpen(false)}
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-lg font-medium text-black"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </Link>
            <Link
              to="/properties"
              className="text-lg font-medium text-black"
              onClick={() => setIsMenuOpen(false)}
            >
              Properties
            </Link>
            {status === "Log Out" && role != "admin" && (
              <>
                <Link
                  to="/myproperties"
                  className="text-lg font-medium text-black"
                  onClick={() => setIsMenuOpen(false)}
                >
                  My Properties
                </Link>
              </>
            )}

            <Link
              to="/login"
              className="bg-green-400 text-black font-bold py-1 px-6 rounded w-fit"
            >
              List Property
            </Link>
            <Link to="/login">
              <button
                className="bg-yellow-400 text-black font-bold py-1 px-6 rounded"
                onClick={() => {
                  if (status === "Log Out") handleLogOut();
                  setIsMenuOpen(false);
                }}
              >
                {status}
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
