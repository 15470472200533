import React from "react";
import { Link } from "react-router-dom";

function Failure() {
  return (
    <div className="">
      <div className="success-content flex flex-col shadow-2xl justify-center items-center md:mx-[25%] md:h-screen">
        <div className="bg-red-600 py-4 px-2 md:py-5 md:px-3 rounded-full mt-4 flex items-center justify-center">
          <i className="fa-solid fa-xmark fa-xl text-white"></i>
        </div>

        <h1 className="text-2xl text-center font-medium w-[60%] md:text-3xl mt-2">
          Your payment failed
        </h1>
        <p className="text-center mt-3 md:text-xl md:px-5">Try again later</p>
        <Link to="/properties">
          <button className="bg-green-950 rounded-full px-4 py-2 text-white font-medium my-5 ">
            Back To Prperties
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Failure;
